import React, { useContext, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import notify from 'notify';
import { REGEXP, STATES } from 'consts';
import { tryGetFirstError } from 'utils/requests';
import getAddressComponents from 'utils/getAddressComponents';
import { Button, Dropdown, Input, InputPlaces } from 'components';
import { uploadDocument, registerEmployee } from './api';
import Context from './context';

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));

const RegisterEmployeeForm = () => {
  const { inviteCode, registerData = {} } = useContext(Context);
  const licenseFileInputRef = useRef<HTMLInputElement>(null);
  const idFileInputRef = useRef<HTMLInputElement>(null);
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      name: '',
      broker_mls_id: '',
      google_address: '',
      address1: '',
      address2: '',
      city: '',
      county: '',
      state: '',
      zip: '',
      password: '',
      passwordConfirm: '',
      license: '',
      idDocument: ''
    }
  });

  const submitForm = async payload => {
    try {
      const signUrl = await registerEmployee(payload, inviteCode!);
      navigate(`/pdf-editor/sign?view=${signUrl.signing_url}`);
    } catch (err) {
      const passwordError = err.response?.params?.password?.[0];
      if (passwordError) setError('password', { type: 'manual', message: passwordError });
      else notify(tryGetFirstError(err));
    }
  };

  const handleAddressChange = (place: google.maps.places.PlaceResult) => {
    const addressComponents = getAddressComponents(place);

    if (!addressComponents) return;

    setValue('address1', addressComponents.address1);
    setValue('city', addressComponents.city);
    setValue('county', addressComponents.county);
    setValue('state', addressComponents.state);
    setValue('zip', addressComponents.zip);
  };

  const handleFileInputChange = async (e, name, ref) => {
    const file: File = e.target.files[0];
    if (!file) return;
    // eslint-disable-next-line no-param-reassign
    ref.current!.value = '';

    try {
      await uploadDocument(
        {
          file,
          doctypeName: name === 'license' ? 'Broker license' : 'Other',
          publicAccess: true
        },
        inviteCode!
      );

      setValue(name, file.name);
      clearErrors(name);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    if (registerData) reset(registerData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerData]);

  return (
    <StyledRegisterEmployeeForm>
      <StyledForm onSubmit={handleSubmit(submitForm)}>
        <h2 className="title">Welcome! Enter your information below.</h2>
        <div className="form-fields-wrapper">
          <Input
            {...register('name')}
            label="Full Name*"
            placeholder="Enter name"
            disabled
          />
          <Input
            {...register('broker_mls_id', { required: 'Required' })}
            error={errors.broker_mls_id?.message}
            label="Broker MLS ID*"
            placeholder="Enter Broker MLS ID"
          />
        </div>
        <div>
          <Controller
            control={control}
            name="google_address"
            defaultValue=""
            rules={{
              required: 'Required',
              maxLength: {
                value: 100,
                message: `Address can not exceed 100 characters`
              },
              pattern: {
                value: REGEXP.ADDRESS,
                message: 'Invalid Address'
              }
            }}
            render={({ field: { onChange, ...field } }) => (
              <InputPlaces
                label="Enter Your Address*"
                placeholder="Enter Address"
                error={errors.google_address?.message}
                autoComplete="off"
                onChange={onChange}
                onPlaceChange={(query, place) => {
                  handleAddressChange(place);
                  onChange(query);
                }}
                {...field}
              />
            )}
          />
        </div>
        <div className="form-fields-wrapper">
          <Input
            {...register('address1', { required: 'Required' })}
            label="Address Line 1*"
            placeholder="Enter Address"
            error={errors.address1?.message}
          />
          <Input
            {...register('address2')}
            label="Address Line 2"
            placeholder="Enter Address"
          />
        </div>
        <div className="form-fields-wrapper">
          <Input
            {...register('city', { required: 'Required' })}
            label="City*"
            placeholder="Enter City"
            error={errors.city?.message}
          />
          <Controller
            control={control}
            rules={{
              required: "Required"
            }}
            name="state"
            defaultValue=""
            render={({ field }) => (
              <Dropdown
                options={statesOptions}
                label="State*"
                placeholder="Select State"
                {...field}
              />
            )}
          />
          <Input
            {...register('zip', {
              pattern: {
                value: REGEXP.ZIP_CODE,
                message: 'Invalid postal code'
              }
            })}
            error={errors.zip?.message}
            label="Zip Code*"
            placeholder="Enter Zip"
          />
        </div>
        <hr />
        <div className="form-fields-wrapper">
          <Input
            {...register('idDocument', { required: 'Required' })}
            label="Upload ID*"
            icon="fc-file"
            placeholder="Select File"
            onClick={() => idFileInputRef.current!.click()}
            error={errors.idDocument?.message}
            readOnly
          />
          <input
            ref={idFileInputRef}
            type="file"
            onChange={e => handleFileInputChange(e, 'idDocument', idFileInputRef)}
            style={{ display: 'none' }}
          />
          <Input
            {...register('license', { required: 'Required' })}
            label="Upload Broker License*"
            icon="fc-file"
            placeholder="Select File"
            onClick={() => licenseFileInputRef.current!.click()}
            error={errors.license?.message}
            readOnly
          />
          <input
            ref={licenseFileInputRef}
            type="file"
            onChange={e => handleFileInputChange(e, 'license', licenseFileInputRef)}
            style={{ display: 'none' }}
          />
        </div>
        <hr />
        <div className="form-fields-wrapper">
          <Input
            {...register('password', {
              required: 'Required',
              pattern: {
                value: REGEXP.PASSWORD,
                message: 'Invalid password'
              }
            })}
            error={errors.password?.message}
            label="Create Password*"
            placeholder="Enter Password"
            type="password"
          />
          <Input
            {...register('passwordConfirm', {
              required: 'Required',
              validate: value => value === getValues('password') || `Passwords don't match`
            })}
            type="password"
            label="Confirm Password*"
            placeholder="Confirm Password"
            error={errors.passwordConfirm?.message}
          />
        </div>
        <Button>Continue</Button>
      </StyledForm>
    </StyledRegisterEmployeeForm>
  );
};

export default RegisterEmployeeForm;

const StyledRegisterEmployeeForm = styled.div`
  padding-bottom: 24px;
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 16px 0 20px;
  }

  .form-fields-wrapper {
    display: grid;
    grid-gap: 0 16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .form-fields-wrapper {
      grid-auto-flow: column;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .form-fields-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
`;

const StyledForm = styled.form`
  .input, .dropdown {
    width: 100%;
    margin: 0 0 24px;
  }

  hr {
    margin-bottom: 24px;
  }

  .button {
    margin-top: 8px;
    padding: 0 96px;
    height: 40px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin: 0 0 32px;
    .button {
      width: 100%;
    }
  }
`;
