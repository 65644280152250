import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { checkInviteCode } from 'views/RegisterPage/api';

interface RegisterEmployeeContextValues {
  inviteCode: string | null;
  inviteCodeError: string | null;
  registerData?: RegisterData;
}

type RegisterData = {
  email: string;
  phone?: string;
  template?: string;
};

const RegisterEmployeeContext = createContext({} as RegisterEmployeeContextValues);

export const RegisterEmployeeContextWrapper = ({ children }) => {
  const { search } = useLocation();
  const inviteCode = new URLSearchParams(search).get('invite_code');

  useEffect(() => {
    if (!inviteCode) navigate('/');
  }, [inviteCode]);

  const [registerData, setRegisterData] = useState<RegisterData>();
  const [inviteCodeError, setInviteCodeError] = useState<null | string>(null);

  useEffect(() => {
    if (!inviteCode) return;
    (async () => {
      try {
        const data = await checkInviteCode(inviteCode);
        setRegisterData(data);
      } catch (err) {
        setInviteCodeError(err.response);
      }
    })();
  }, [inviteCode]);

  if (!inviteCode) return null;

  const contextValues: RegisterEmployeeContextValues = {
    inviteCode,
    inviteCodeError,
    registerData
  };

  return (
    <RegisterEmployeeContext.Provider value={contextValues}>
      {children}
    </RegisterEmployeeContext.Provider>
  );
};

export default RegisterEmployeeContext;
