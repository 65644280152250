import { fetch } from 'utils/fetch';
import { urlEncodeBody, uriEncodeBody } from 'utils/requests';
import keysToCamel from 'utils/keysToCamel';

export async function uploadDocument(
  values: {
    file: File;
    doctypeName?: string;
    draft?: boolean;
    userId?: number;
    publicAccess?: boolean;
  },
  inviteCode: string
): Promise<number> {
  // presign document
  const { presigned_url: presignedUrl, document_id: documentId } = await (
    await fetch(`/register/document?invite_code=${inviteCode}`, {
      method: 'POST',
      body: urlEncodeBody({
        filename: values.file.name,
        size: values.file.size,
        doctype: values.doctypeName,
        is_draft: values.draft ? 1 : 0,
        user_id: values.userId,
        public_access: values.publicAccess ? 1 : 0
      })
    })
  ).json();
  // upload file to s3
  await fetch(presignedUrl, { method: 'PUT', body: values.file });
  // confirm upload success
  await fetch(`/register/upload_success/${documentId}?invite_code=${inviteCode}`, {
    method: 'PUT'
  });

  return documentId;
}

export async function registerEmployee(
  values: {
    broker_mls_id: string;
    password: string;
    google_address: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  },
  inviteCode: string
): Promise<{signing_url}> {
  const response = await fetch(`/register/contract/${inviteCode}`, {
    method: 'POST',
    body: urlEncodeBody({
      broker_mls_id: values.broker_mls_id,
      password: values.password,
      google_address: values.google_address,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip
    })
  });

  const data = await response.json();

  return data;
}
