import React from 'react';
import HeaderOnlyLayout from 'components/HeaderOnlyLayout';
import RegisterEmployeePage from 'views/RegisterEmployeePage';

const RegisterEmployee = () => {
  return (
    <HeaderOnlyLayout>
      <RegisterEmployeePage />
    </HeaderOnlyLayout>
  );
};

export default RegisterEmployee;
