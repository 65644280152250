import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import RegisterEmployeeForm from './RegisterEmployeeForm';
import Context, { RegisterEmployeeContextWrapper } from './context';

const RegisterEmployeePage = () => {
  const { inviteCodeError } = useContext(Context);

  if (inviteCodeError) {
    return (
      <StyledRegisterEmployeePage className="invite-code-error">
        <h1 className="title">{inviteCodeError}</h1>
        <div className="info">
          Request a new link or <Link to="/login">sign up →</Link>
        </div>
        <Link className="about" to="/login">
          What is XchangePlus? →
        </Link>
      </StyledRegisterEmployeePage>
    );
  }

  return (
    <StyledRegisterEmployeePage>
      <RegisterEmployeeForm />
    </StyledRegisterEmployeePage>
  );
};

export default props => (
  <RegisterEmployeeContextWrapper>
    <RegisterEmployeePage {...props} />
  </RegisterEmployeeContextWrapper>
);

const StyledRegisterEmployeePage = styled.div`
  &.invite-code-error {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 890px;

    .about {
      margin-top: auto;
    }
  }
`;
